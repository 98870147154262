<template>
  <div class="pb-32">
    <template v-if="stats.loading">
      <loader />
    </template>
    <template v-else>
      <h2 class="text-xl font-bold mb-4">
        Loan Statistics
      </h2>
      <template>
        <div class="flex">
          <div class="w-full data-table-control mb-3 flex">
            <date-range-picker
              ref="picker"
              :locale-data="{ firstDay: 1, format: dateRange.format }"
              :singleDatePicker="dateRange.singleDatePicker"
              :autoApply="dateRange.autoApply"
              v-model="dateRange.value"
              opens="center"
              @update="updateDateRange"
              :append-to-body="true"
              @toggle="() => {}"
            />
            <button
              @click.prevent="resetStatistics"
              type="button"
              class="ml-6 btn-sm btn-blue rounded-sm px-6"
            >
              Reset
            </button>
          </div>
        </div>
      </template>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4" style="margin-top: 1px; margin-left: 1px">
        <template v-for="(stat, n) in stats.data">
          <div class="col-span-1 p-6 border" style="margin-left: -1px; margin-top: -1px;" :key="n">
            <div class="text-base font-thin text-gray-900 mb-3 flex">
              <span class="mr-2">{{ stat.key | fromSlug | sentenceCase }}</span>
              <tool-tip :message="mapData(stat.key)">                
                <ion-icon name="information-circle-outline" class="cursor-pointer text-xl"></ion-icon>
              </tool-tip>
            </div>
            <div class="text-sm py-1" v-if="stat.type == 'amount'">
              <span>Principal:</span> <span class=" font-bold">₦ {{ stat.principal || 0 | currency }}</span>
            </div>
            <div class="text-sm py-1" v-if="stat.type == 'total'">
              <span>Total:</span> <span class=" font-bold">₦ {{ stat.total || 0 | currency }}</span>
            </div>
            <div class="text-sm py-1" v-if="stat.interest">
              <span>Interest:</span> <span class="font-bold">₦ {{ stat.interest | currency }}</span>
            </div>
            <!-- <div class="text-sm font-bold" v-else>{{ stat.value }}</div> -->
            <div class="text-sm py-1" v-if="stat.percentage">
              <span>Percentage:</span> <span class="font-bold">{{ stat.percentage.toFixed(2) }} %</span>
            </div>
          </div>
        </template>
      </div>
    </template>
    
    <section class="mt-10">
      <credit-card-statistics />
    </section>
  </div>
</template>

<script>
  export default {
    name: 'RepaymentStatistics',
    components: {
      CreditCardStatistics: () => import('../credit-card-statistics/Index.vue'),
      dateRangePicker: () => import('vue2-daterange-picker'),
    },
    data() {
      return {
        stats: this.$options.resource([]),
        dateRange: {
          value: {
            startDate: null,
            endDate: null,
          },
          format: "dd/mm/yyyy",
        },
        dataDetails: {
          "total_disbursed": "Sum of all loans disbursed(using disbursed_at column)",
          "total_repayment": "Sum of all repayments",
          "total_outstanding": "Sum of all unpaid repayments(principal amount). \n(sum(requested_amount/tenure))",
          "total_expected": "Sum of all unpaid repayments(principal and interest)",
          "total_repayment_undue": "Sum of all repayment not due(where repayment status is undue)",
          "total_repayment_due": "Sum of all repayment due(where status is due). \n(interest = sum(interest/100 * tenure * requested_amount)) \n(principal = sum(requested_amount/tenure))",
          "total_repayment_received": "Sum of all repayment received(where status is paid or transferred). \n(interest = sum(interest/100 * tenure * requested_amount)) \n(principal = sum(requested_amount/tenure))",
          "total_default": "Sum of all default repayment(where status is due and defualted is true).\n(interest = sum(interest/100 * tenure * requested_amount)) \n(principal = sum(requested_amount/tenure))", 
          "accrued_interest": "Sum of all accrued interest. \n(sum((requested_amount * (interest/100) * tenure) / (tenure * daysInMonth(due_date))))"
        }
      }
    },
    computed: {
      query() {
        let rangeStart = this.dateRange.value.startDate;
        if (rangeStart) {
          let date = new Date(rangeStart);
          rangeStart = `${date.getFullYear()}-${date.getMonth() +
            1}-${date.getDate()}`;
        }
        let rangeEnd = this.dateRange.value.endDate;
        if (rangeEnd) {
          let date = new Date(rangeEnd);
          rangeEnd = `${date.getFullYear()}-${date.getMonth() +
            1}-${date.getDate()}`;
        }

        const obj = {
          rangeEnd,
          rangeStart,
        };

        var query_string = Object.keys(obj)
          .map((key) => {
            if (obj[key]) {
              return `${key}=${obj[key]}`;
            }
          })
          .filter((key) => !!key)
          .join("&");

        return query_string;
      },
    },
    beforeMount() {
      this.getStats();
    },
    methods: {
      mapData(key) {
        const value = this.dataDetails[key];
        return value ?? "";
      },
      resetStatistics() {
        this.dateRange.value = { startDate: null, endDate: null };
        this.getStats();
      },
      updateDateRange() {
        this.getStats();
      },
      async getStats() {
        this.stats.loading = true;
        await this.sendRequest('admin.repayments.statistics', this.query, {
          success: response => {
            this.stats.data = response.data.statistics;
          },
          error: error => {
            this.stats.error = error;
          }
        });

        this.stats.loading = false;
      }
    }
  }
</script>